import axios from 'axios'
import qs from 'qs'
import { Message, Notification } from 'element-ui'
import router from '@/router'
import store from '@/store'

let token = sessionStorage.getItem('token')
// console.log(sessionStorage.getItem('token'),222)

// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api/' : '/api', // api的base_url
  timeout: 30000, // 请求超时时间,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
    //  'X-Requested-With': 'XMLHtx`tpRequest'
    // 'token':window.sessionStorage.getItem('token')
  },
})

// axios.defaults.headers.common["token"] = token?token:'';
//
// console.log(axios.defaults)
// axios.defaults.withCredentials = true

// request请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers.token = window.sessionStorage.getItem('token')
    config.data = config.data || {}
    config.method = config.method || 'post'
    return config
  },
  (error) => {
    console.log('error>>>>>>>>>>', error) // for debug
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  (response) => {
    const { err_code, data, returnMsg } = response.data

    // console.log('router.currentRoute+++',router.currentRoute)

    // success
    if (err_code == 0 || err_code == 200) {
      return response.data
    } else {
      const errorTxt = returnMsg || '操作失败'
      console.log(errorTxt)
      errorTip(errorTxt)
      return Promise.reject(response.data)
    }
  },
  (error) => {
    console.log('net work err', error) // for debug
    // if(error.indexOf('timeout') > -1){
    //     errorTip('网络错误,请刷新再试')
    // }else{
    Notification({
      title: '错误提示',
      message: error,
    })
    // }

    return Promise.reject('网络错误,请刷新再试')
  }
)

function errorTip(errTxt) {
  Message({
    message: errTxt,
    type: 'error',
    duration: 1 * 1000,
  })
  return
}

export default service
